import React from 'react'
import { PageLayout, PageBreadcrumb } from '../../components'
import { t } from '../../lib/locale'

const ContentCustomerOfTheMonthTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  const renderEN = (
    <>
      <p>Because we are so grateful for all your online and offline vitis. Because we are continuously motivated by your sweet reviews. And because you make our job a lot more fun with your fun messages. Yup, because of all those reasons we give away each month one gift voucher of €50 to one of our loyal fans.</p>
      <h3>What do you need to do to participate?</h3>
      <ul>
        <li>Publish a photo of your purchases on Instagram, and tag @shopupnorth_com</li>
        <li>Or write an honest review on our Facebook page.</li>
      </ul>
      <p>Are you that excited and you participated on both Instagram and Facebook. Good for you! You have doubled your chances of winning. Best of luck to you!</p>
      <p><b>Be mindful</b>: we only see the photos that are accessible publicly. If you have a pricate profifle and you'd like to participate anyway? Send us a screenshot, and we'll add your name to our lottery! ;)</p>

      <h3>The winners of 2020 were: </h3>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Januari
            </div>
            <div className="card-body">
              @lille-hygge
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Februari
            </div>
            <div className="card-body">
              @kim_dc
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Maart
            </div>
            <div className="card-body">
              @duizend.woorden
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              April
            </div>
            <div className="card-body">
              @jantine_versturen
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Mei
            </div>
            <div className="card-body">
              @annickswaans
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Juni
            </div>
            <div className="card-body">
              @britt_muyldermans
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Juli
            </div>
            <div className="card-body">
              @somethingelz
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Augustus
            </div>
            <div className="card-body">
              @birgitluijk
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              September
            </div>
            <div className="card-body">
              Marianne Claes
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Oktober
            </div>
            <div className="card-body">
              @roelantsdecorations
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              November
            </div>
            <div className="card-body">
              Rob Van Ham
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              December
            </div>
            <div className="card-body">
              Chantal Hermans
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const renderNL = (
    <>
      <p>Omdat we ontzettend dankbaar zijn voor al jullie online én offline bezoekjes. Omdat we nog gemotiveerder zijn dankzij jullie lieve reviews. En omdat jullie onze job nog leuker maken met zo’n enthousiaste berichtjes. Jup, omwille van al deze redenen verloten wij met plezier elke maand één cadeaubon t.w.v. €50 onder onze grootste fans.</p>
      <h3>Wat moet je hiervoor doen?</h3>
      <ul>
        <li>Plaats een foto van jouw aankopen op Instagram, en tag @shopupnorth_com</li>
        <li>Of schrijf een eerlijke review op onze Facebookpagina.</li>
      </ul>
      <p>Ben je zo enthousiast en doe je mee op Facebook en op Instagram? Dan maak je dubbel zoveel kans. Veel succes!</p>
      <p><b>Let op</b>: wij zien enkel de foto’s die publiek geplaatst worden. Heb je een privé-profiel en wil je toch graag meedoen? Stuur ons dan een screenshot door, en we voegen jouw deelname toe aan onze lotterij! ;)</p>

      <h3>De winnaars voor 2020 waren: </h3>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Januari
            </div>
            <div className="card-body">
              @lille-hygge
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Februari
            </div>
            <div className="card-body">
              @kim_dc
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Maart
            </div>
            <div className="card-body">
              @duizend.woorden
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              April
            </div>
            <div className="card-body">
              @jantine_versturen
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Mei
            </div>
            <div className="card-body">
              @annickswaans
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Juni
            </div>
            <div className="card-body">
              @britt_muyldermans
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Juli
            </div>
            <div className="card-body">
              @somethingelz
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Augustus
            </div>
            <div className="card-body">
              @birgitluijk
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              September
            </div>
            <div className="card-body">
              Marianne Claes
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              Oktober
            </div>
            <div className="card-body">
              @roelantsdecorations
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              November
            </div>
            <div className="card-body">
              Rob Van Ham
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <div className="card">
            <div className="card-header">
              December
            </div>
            <div className="card-body">
              Chantal Hermans
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <PageLayout className="shopping-bag" lang={lang} switcher={page}>
      <PageBreadcrumb current={page} items={page.breadcrumbs} lang={lang} />
      <h1>{t(page, 'title', lang)}</h1>
      {+lang.id_lang === 1 && renderEN}
      {+lang.id_lang === 4 && renderNL}
    </PageLayout>
  )
}

export default ContentCustomerOfTheMonthTemplate
